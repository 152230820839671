import Axios from 'axios';

import Cookies from "universal-cookie";

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

// Delete data
export function Reg_DeleteData(currentPage, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id', id);
    data.append('edit_data', type);
    data.append('type_page', currentPage);
    console.log(currentPage);
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        if(currentPage == 'access_dash' || currentPage == 'access_dash_details'){
            SetListDataSingle('access_dash', response.data.access_dash);

        }else if(currentPage == 'revenue' || currentPage == 'revenue_details'){
            SetListDataSingle('revenue', response.data.revenue);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit data user
export function Reg_UserDash(userId, idPage, name, email, pass, file, nivel, note, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_profile');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);
    data.append('nivel', nivel);
    data.append('note', note);

    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){
            const newData = response.data.access_dash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
            }

            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('name', newData['name']);
            SetUserData('email', newData['email']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit Revenue
export function Reg_Revenue(userId, idPage, type, operation, buyer, cnpj, email, cep, address, number, city, state, cash, qtd, freight, discount, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'revenue');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('type', type);
    data.append('operation', operation);
    data.append('buyer', buyer);
    data.append('cnpj', cnpj);
    data.append('email', email);
    data.append('cep', cep);
    data.append('address', address);
    data.append('number', number);
    data.append('city', city);
    data.append('state', state);
    data.append('cash', cash);
    data.append('qtd', qtd);
    data.append('freight', freight);
    data.append('discount', discount);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListDataSingle('revenue', response.data.revenue);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}