export function SvgClose(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M18 6L6 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6 6L18 18" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgImg(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 10C9.32843 10 10 9.32843 10 8.5C10 7.67157 9.32843 7 8.5 7C7.67157 7 7 7.67157 7 8.5C7 9.32843 7.67157 10 8.5 10Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 15L16 10L5 21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgErro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function SvgChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function SvgMenuHamburguer(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <g clipPath="url(#clip0_241_54)">
                <path d="M3.75 12H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 6H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 18H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_241_54">
                    <rect width="24" height="24" fill={ props.color }/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function SvgDashboard(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M9.75 3.75H5.25C4.85218 3.75 4.47064 3.90804 4.18934 4.18934C3.90804 4.47064 3.75 4.85218 3.75 5.25V9.75C3.75 10.1478 3.90804 10.5294 4.18934 10.8107C4.47064 11.092 4.85218 11.25 5.25 11.25H9.75C10.1478 11.25 10.5294 11.092 10.8107 10.8107C11.092 10.5294 11.25 10.1478 11.25 9.75V5.25C11.25 4.85218 11.092 4.47064 10.8107 4.18934C10.5294 3.90804 10.1478 3.75 9.75 3.75ZM9.75 9.75H5.25V5.25H9.75V9.75ZM18.75 3.75H14.25C13.8522 3.75 13.4706 3.90804 13.1893 4.18934C12.908 4.47064 12.75 4.85218 12.75 5.25V9.75C12.75 10.1478 12.908 10.5294 13.1893 10.8107C13.4706 11.092 13.8522 11.25 14.25 11.25H18.75C19.1478 11.25 19.5294 11.092 19.8107 10.8107C20.092 10.5294 20.25 10.1478 20.25 9.75V5.25C20.25 4.85218 20.092 4.47064 19.8107 4.18934C19.5294 3.90804 19.1478 3.75 18.75 3.75ZM18.75 9.75H14.25V5.25H18.75V9.75ZM9.75 12.75H5.25C4.85218 12.75 4.47064 12.908 4.18934 13.1893C3.90804 13.4706 3.75 13.8522 3.75 14.25V18.75C3.75 19.1478 3.90804 19.5294 4.18934 19.8107C4.47064 20.092 4.85218 20.25 5.25 20.25H9.75C10.1478 20.25 10.5294 20.092 10.8107 19.8107C11.092 19.5294 11.25 19.1478 11.25 18.75V14.25C11.25 13.8522 11.092 13.4706 10.8107 13.1893C10.5294 12.908 10.1478 12.75 9.75 12.75ZM9.75 18.75H5.25V14.25H9.75V18.75ZM18.75 12.75H14.25C13.8522 12.75 13.4706 12.908 13.1893 13.1893C12.908 13.4706 12.75 13.8522 12.75 14.25V18.75C12.75 19.1478 12.908 19.5294 13.1893 19.8107C13.4706 20.092 13.8522 20.25 14.25 20.25H18.75C19.1478 20.25 19.5294 20.092 19.8107 19.8107C20.092 19.5294 20.25 19.1478 20.25 18.75V14.25C20.25 13.8522 20.092 13.4706 19.8107 13.1893C19.5294 12.908 19.1478 12.75 18.75 12.75ZM18.75 18.75H14.25V14.25H18.75V18.75Z"/>
        </svg>
    )
}

export function SvgDashboardExit(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z" />
        </svg>
    )
}

export function SvgDashboardUserDash(props){
    return(
        <svg viewBox="0 0 32 32" className={ props.className } fill="none">
            <g clipPath="url(#clip0_538_3993)">
                <path d="M10.5 20C14.0899 20 17 17.0899 17 13.5C17 9.91015 14.0899 7 10.5 7C6.91015 7 4 9.91015 4 13.5C4 17.0899 6.91015 20 10.5 20Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M1.27875 25.0001C2.27761 23.4643 3.64424 22.2023 5.25458 21.3286C6.86491 20.455 8.66795 19.9974 10.5 19.9974C12.332 19.9974 14.1351 20.455 15.7454 21.3286C17.3558 22.2023 18.7224 23.4643 19.7212 25.0001" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.5 20C23.332 19.9989 25.1351 20.4558 26.7456 21.329C28.356 22.2022 29.7227 23.4641 30.7213 25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19.0862 7.4625C19.9754 7.10787 20.9319 6.954 21.8874 7.0119C22.8429 7.0698 23.7738 7.33802 24.6136 7.79742C25.4535 8.25681 26.1815 8.89602 26.7456 9.66939C27.3097 10.4428 27.6961 11.3312 27.8771 12.2712C28.058 13.2112 28.0291 14.1795 27.7925 15.1071C27.5558 16.0346 27.1172 16.8984 26.5079 17.6368C25.8987 18.3752 25.1339 18.9698 24.2682 19.3784C23.4025 19.7869 22.4572 19.9992 21.5 20" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_538_3993">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function SvgDashboardMenuPoint(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
        </svg>
    )
}

export function SvgDashboardUser(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    )
}

export function SvgDashboardDocuments(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M15 7H20.5L15 1.5V7M8 0H16L22 6V18C22 19.11 21.11 20 20 20H8C6.89 20 6 19.1 6 18V2C6 .89 6.89 0 8 0M17 16V14H8V16H17M20 12V10H8V12H20Z" />
        </svg>
    )
}

export function SvgDashboardTributes(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M15.75 14.25C15.75 14.4489 15.671 14.6397 15.5303 14.7803C15.3897 14.921 15.1989 15 15 15H9C8.80109 15 8.61032 14.921 8.46967 14.7803C8.32902 14.6397 8.25 14.4489 8.25 14.25C8.25 14.0511 8.32902 13.8603 8.46967 13.7197C8.61032 13.579 8.80109 13.5 9 13.5H15C15.1989 13.5 15.3897 13.579 15.5303 13.7197C15.671 13.8603 15.75 14.0511 15.75 14.25ZM15 10.5H9C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25C8.25 11.4489 8.32902 11.6397 8.46967 11.7803C8.61032 11.921 8.80109 12 9 12H15C15.1989 12 15.3897 11.921 15.5303 11.7803C15.671 11.6397 15.75 11.4489 15.75 11.25C15.75 11.0511 15.671 10.8603 15.5303 10.7197C15.3897 10.579 15.1989 10.5 15 10.5ZM20.25 4.49999V20.25C20.25 20.6478 20.092 21.0293 19.8107 21.3106C19.5294 21.592 19.1478 21.75 18.75 21.75H5.25C4.85218 21.75 4.47064 21.592 4.18934 21.3106C3.90804 21.0293 3.75 20.6478 3.75 20.25V4.49999C3.75 4.10216 3.90804 3.72063 4.18934 3.43933C4.47064 3.15802 4.85218 2.99999 5.25 2.99999H8.64937C9.07079 2.52817 9.58709 2.15066 10.1645 1.8922C10.7419 1.63373 11.3674 1.50012 12 1.50012C12.6326 1.50012 13.2581 1.63373 13.8355 1.8922C14.4129 2.15066 14.9292 2.52817 15.3506 2.99999H18.75C19.1478 2.99999 19.5294 3.15802 19.8107 3.43933C20.092 3.72063 20.25 4.10216 20.25 4.49999ZM9 5.99999H15C15 5.20434 14.6839 4.44127 14.1213 3.87867C13.5587 3.31606 12.7956 2.99999 12 2.99999C11.2044 2.99999 10.4413 3.31606 9.87868 3.87867C9.31607 4.44127 9 5.20434 9 5.99999ZM18.75 4.49999H16.2422C16.4128 4.98169 16.5 5.48896 16.5 5.99999V6.74999C16.5 6.9489 16.421 7.13966 16.2803 7.28032C16.1397 7.42097 15.9489 7.49999 15.75 7.49999H8.25C8.05109 7.49999 7.86032 7.42097 7.71967 7.28032C7.57902 7.13966 7.5 6.9489 7.5 6.74999V5.99999C7.50002 5.48896 7.58721 4.98169 7.75781 4.49999H5.25V20.25H18.75V4.49999Z" />
        </svg>
    )
}

export function SvgDashboardRH(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />
        </svg>
    )
}

export function SvgDashboardCash(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M5,6H23V18H5V6M14,9A3,3 0 0,1 17,12A3,3 0 0,1 14,15A3,3 0 0,1 11,12A3,3 0 0,1 14,9M9,8A2,2 0 0,1 7,10V14A2,2 0 0,1 9,16H19A2,2 0 0,1 21,14V10A2,2 0 0,1 19,8H9M1,10H3V20H19V22H1V10Z" />
        </svg>
    )
}

export function SvgUserDisabled(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z" />
        </svg>
    )
}

export function SvgCancel(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
        </svg>
    )
}

export function SvgDelete(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M3 6H5H21" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 11V17" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgEdit(props){
    return(
        <svg viewBox="0 0 24 25" fill="none" className={ props.className }>
            <path d="M11 4.2381H4C3.46957 4.2381 2.96086 4.44881 2.58579 4.82388C2.21071 5.19896 2 5.70767 2 6.2381V20.2381C2 20.7685 2.21071 21.2772 2.58579 21.6523C2.96086 22.0274 3.46957 22.2381 4 22.2381H18C18.5304 22.2381 19.0391 22.0274 19.4142 21.6523C19.7893 21.2772 20 20.7685 20 20.2381V13.2381" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.5 2.73808C18.8978 2.34026 19.4374 2.11676 20 2.11676C20.5626 2.11676 21.1022 2.34026 21.5 2.73808C21.8978 3.13591 22.1213 3.67547 22.1213 4.23808C22.1213 4.80069 21.8978 5.34026 21.5 5.73808L12 15.2381L8 16.2381L9 12.2381L18.5 2.73808Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgUser(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    )
}

export function SvgSearch(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={ props.className }>
            <circle cx="11" cy="11" r="8"/>
            <line x1="21" y1="21" x2="16.65" y2="16.65"/>
        </svg>
    )
}

export function SvgSetaRight(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function SvgSave(props){
    return(
        <svg viewBox="0 0 32 32" className={ props.className } fill="none">
            <g clipPath="url(#clip0_280_1228)">
                <path d="M27 11.4137V26C27 26.2652 26.8946 26.5196 26.7071 26.7071C26.5196 26.8946 26.2652 27 26 27H6C5.73478 27 5.48043 26.8946 5.29289 26.7071C5.10536 26.5196 5 26.2652 5 26V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H20.5863C20.8511 5.00012 21.1051 5.10532 21.2925 5.2925L26.7075 10.7075C26.8947 10.8949 26.9999 11.1489 27 11.4137Z" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10 27V19C10 18.7348 10.1054 18.4804 10.2929 18.2929C10.4804 18.1054 10.7348 18 11 18H21C21.2652 18 21.5196 18.1054 21.7071 18.2929C21.8946 18.4804 22 18.7348 22 19V27" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M19 9H12" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_280_1228">
                    <rect width="32" height="32" fill={ props.color }/>
                </clipPath>
            </defs>
        </svg>
    )
}