import { useState, useEffect, useRef } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'dataFixed';

import { SvgDashboard, SvgDashboardCash, SvgDashboardClipBoard, SvgDashboardDocuments, SvgDashboardExit, SvgDashboardMenuPoint, SvgDashboardRH, SvgDashboardTributes, SvgDashboardUser, SvgMenuHamburguer } from 'components/SvgFile';

import PopUp_ShowFile from 'components/PopUp/ShowFile';
import Page_AccessDash from 'components/Pages/AccessDash';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import Page_AccessDash_Details from 'components/Pages/AccessDash/Details';
import Page_Revenue from 'components/Pages/Revenue';
import Page_Revenue_Details from 'components/Pages/Revenue/Details';

export default function Contents(){

    const width = useRef();

    const cookies = new Cookies();
    const [ status, setStatus ] = useState(false);

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function ClickPage(value){
        SetListPag('currentPage', value);
    }
    
    function CurrentPage(){
        switch(currentPage) {
            case "revenue":
                return <Page_Revenue ClickPage={ ClickPage } />;
            case "revenue_details":
                return <Page_Revenue_Details ClickPage={ ClickPage } />;

            case "access_dash":
                return <Page_AccessDash ClickPage={ ClickPage } />;
            case "access_dash_details":
                return <Page_AccessDash_Details ClickPage={ ClickPage } />;

            default:
                return(
                    <div className="no_data_content">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function  CloseMobile(){
        if(width?.current.clientWidth <= 560){
            setStatus(false);
        }
    }

    function Logout(){
        SetListPag('page', 'index');

        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents" ref={ width }>
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">

                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="div_logotipo">
                                    <img alt="logotipo" src="./logo192.png" className="svg_logotipo" />
                                </div>
                                <div className={ status == true ? "icon_menu_hamburguer hide_menu_hamburguer" : "icon_menu_hamburguer" }>
                                    <SvgMenuHamburguer color="#ffffff" className="icons" />
                                </div>
                            </div>
                        </div>
                        
                        <div className={ status == true ? "opt_menu_dash opt_menu_dash_open" : "opt_menu_dash" }>
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("index"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Dashboard</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "revenue" || currentPage == "revenue_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("revenue"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardCash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Receita</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "spending" || currentPage == "spending_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("spending"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardDocuments color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Gastos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "tributes" || currentPage == "tributes_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("tributes"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardTributes color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Tributos e guias</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "accounting" || currentPage == "accounting_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("accounting"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardCash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Contábil</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "rh" || currentPage == "rh_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("rh"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardRH color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>RH</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "docs" || currentPage == "docs_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("docs"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardDocuments color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Docs</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "extracts" || currentPage == "extracts_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("extracts"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardCash color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Extratos</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "reports" || currentPage == "reports_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("reports"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardTributes color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Relatórios</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "access_dash" || currentPage == "access_dash_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ ClickPage("access_dash"); CloseMobile(); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboardUser color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Usuários</div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{
                                            let iconMenu   = '';
                                            switch (key.page) {
                                                case "revenue": 
                                                    iconMenu = <SvgDashboardCash color="#FFC400" className="icons_dash" />;

                                                case "spending":
                                                    iconMenu = <SvgDashboardDocuments color="#FFC400" className="icons_dash" />;

                                                case "tributes":
                                                    iconMenu = <SvgDashboardTributes color="#FFC400" className="icons_dash" />;

                                                case "accounting":
                                                    iconMenu = <SvgDashboardCash color="#FFC400" className="icons_dash" />;

                                                case "rh":
                                                    iconMenu = <SvgDashboardRH color="#FFC400" className="icons_dash" />;

                                                case "docs":
                                                    iconMenu = <SvgDashboardDocuments color="#FFC400" className="icons_dash" />;

                                                case "extracts":
                                                    iconMenu = <SvgDashboardCash color="#FFC400" className="icons_dash" />;

                                                case "reports":
                                                    iconMenu = <SvgDashboardTributes color="#FFC400" className="icons_dash" />;
                                            }
                                            
                                            let activeMenu = '';
                                            if(key.page == 'revenue' && currentPage == 'revenue_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'spending' && currentPage == 'spending_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'tributes' && currentPage == 'tributes_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'accounting' && currentPage == 'accounting_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'rh' && currentPage == 'rh_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'docs' && currentPage == 'docs_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'extracts' && currentPage == 'extracts_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(key.page == 'reports' && currentPage == 'reports_details'){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else if(currentPage == key.page){
                                                status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                            }else {
                                                status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                            }
                                            
                                            return(
                                                <div className={ activeMenu } onClick={ ()=>{ ClickPage(key.page); CloseMobile(); } } key={ index }>
                                                    <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                        <div className="div_icons">
                                                            { iconMenu }
                                                        </div>
                                                        <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>
                                                            { key.name }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "name_menu_dash menu_close" : "name_menu_dash" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgDashboardUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgDashboardMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_Confirmation />
            <PopUP_ReturnResponse />
            <PopUP_EditProfile />     
            <PopUp_ShowFile />       
        </div>
    )
}
