import React, { useState, useEffect } from "react";

import './EditProfile.css';

import { SvgClose, SvgImg } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";
import { Reg_UserDash } from "services/RegisterData";

export default function PopUP_EditProfile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ id, setId ]         = useState('');
    const [ name, setName ]     = useState('');
    const [ email, setEmail ]   = useState('');
    const [ pass, setPass ]     = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterModalData('Profile', setModaldata);
        RegisterModalObserver('Profile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setId(GetUserData('id'));
            setName(GetUserData('name'));
            setEmail(GetUserData('email'));
            setFile(GetUserData('file'));
        }
    }, [showPopUp]);

    function SaveData(){
        Reg_UserDash(id, name, email, pass, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
        ClosePopUp();
    }

    function ClosePopUp(){
        setStatus(false);
        SetModalState('Profile', false);
    }

    function AddFile(value){
        if(value){
            setStatus(true);
            setFile(value);
        }else {
            setStatus(false);
            setFile('');
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_profile">
                        <div className="div_data type_div">
                            <div className="title">
                                Editar dados do perfil
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                                        <span className="name_input">Senha</span>
                                    </div>
                                </div>
                                
                                <div className="list_input_data">
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { status == true ? "Foto adicionada" : "Adicionar foto" }
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            file !='' ?
                                                status == true ?
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div> :
                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                                    <SvgImg color="#324d6b" className="icons"/>
                                                </div>
                                            : null
                                        }
                                    </div>
                                </div>

                                <div className="register_data button_category" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
