import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Revenue, Reg_UserDash } from "services/RegisterData";

export default function Page_Revenue_Details(){

    const [ loading, setLoading ]   = useState(false);

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("revenue"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ type, setType ]           = useState(InitialData('type'));
    const [ operation, setOperation ] = useState(InitialData('operation'));
    const [ buyer, setBuyer ]         = useState(InitialData('buyer'));
    const [ cnpj, setCnpj ]           = useState(InitialData('cnpj'));
    const [ email, setEmail ]         = useState(InitialData('email'));
    const [ cep, setCep ]             = useState(InitialData('cep'));
    const [ address, setAddress ]     = useState(InitialData('address'));
    const [ number, setNumber ]       = useState(InitialData('number'));
    const [ city, setCity ]           = useState(InitialData('city'));
    const [ state, setState ]         = useState(InitialData('state'));
    const [ cash, setCash ]           = useState(InitialData('cash'));
    const [ qtd, setQtd ]             = useState(InitialData('qtd'));
    const [ freight, setFreight ]     = useState(InitialData('freight'));
    const [ discount, setDiscount ]   = useState(InitialData('discount'));

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);

        RegisterDataPage('revenue', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("revenue"));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        setOperation(InitialData('operation'));
        setBuyer(InitialData('buyer'));
        setCnpj(InitialData('cnpj'));
        setEmail(InitialData('email'));
        setCep(InitialData('cep'));
        setAddress(InitialData('address'));
        setNumber(InitialData('number'));
        setCity(InitialData('city'));
        setState(InitialData('state'));
        setCash(InitialData('cash'));
        setQtd(InitialData('qtd'));
        setFreight(InitialData('freight'));
        setDiscount(InitialData('discount'));
    }, [dataPage, idPage]);


    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Revenue(userId, idPage, type, operation, buyer, cnpj, email, cep, address, number, city, state, cash, qtd, freight, discount, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'revenue');
        SetListPag('currentPageId', 'remuve');
    }
    
    return(
        <div className="Page_Revenue_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="fixed_mobile name_fixed">
                            Dashboard
                        </div>
                        <div className="fixed_mobile show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Receita
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setType(e.target.value) } } value={ type } maxLength="140" required />
                            <span className="name_input">Tipo</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setOperation(e.target.value) } } value={ operation } maxLength="140" required />
                            <span className="name_input">Tipo de operação</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setBuyer(e.target.value) } } value={ buyer } maxLength="140" required />
                            <span className="name_input">Comprador</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpj } maxLength="140" required />
                            <span className="name_input">CNPJ/CPF</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } maxLength="140" required />
                            <span className="name_input">E-mail</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Valores</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } maxLength="30" required />
                            <span className="name_input">Valor unitário</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="qtd" onChange={ (e)=>{ setQtd(e.target.value) } } value={ qtd } maxLength="30" required />
                            <span className="name_input">Qtd</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="freight" onChange={ (e)=>{ setFreight(e.target.value) } } value={ freight } maxLength="30" required />
                            <span className="name_input">Frete</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="discount" onChange={ (e)=>{ setDiscount(e.target.value) } } value={ discount } maxLength="30" required />
                            <span className="name_input">Desconto</span>
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Endereço</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value) } } value={ cep } maxLength="30" required />
                            <span className="name_input">CEP</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } maxLength="30" required />
                            <span className="name_input">Endereço</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength="30" required />
                            <span className="name_input">N°</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } maxLength="30" required />
                            <span className="name_input">Cidade</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="state" onChange={ (e)=>{ setState(e.target.value) } } value={ state } maxLength="30" required />
                            <span className="name_input">Estado</span>
                        </div>
                    </div>
                </div>
                
            </form>
        </div>
    )
}